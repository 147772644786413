import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PrivateLayout from "../../Layouts/PrivateLayout";
import Sidebar from "./sidebar";
import { getLocalData } from "../../utils/functions";
import Transactions from "./transactions";
import Restaurants from "./restaurants";
import Meals from "./meals";
import Orders from "./orders";
import Invoices from "./invoices";
import AddNewBag from "./add-new-bag";
import AddNewInvoice from "./add-new-invoice";
import InvoiceDetails from "./invoice-details";
import Profile from "./Profile";
import Header from "./header";

const Dashboard = ({ history }) => {
  const [showSidebar, setShowSidebar] = useState(false);

  const pathName = window.location.pathname;

  return (
    <PrivateLayout history={history}>
      <div className="layout-wrapper layout-content-navbar page-container">
        <div className="layout-container">
          <Sidebar
            pathName={pathName}
            history={history}
            showSidebar={showSidebar}
            setShowSidebar={setShowSidebar}
          />

          <div className="layout-page">
            <Header
              history={history}
              setShowSidebar={setShowSidebar}
              showSidebar={showSidebar}
            />

            <div className="content-wrapper mt-4">
              {pathName === "/wnwn/admin" && <Restaurants />}
              {pathName === "/wnwn/admin/stores" && <Restaurants />}
              {(pathName === "/wnwn/admin/meals" ||
                pathName === "/wnwn/admin/bags") && (
                <Meals history={history} />
              )}
              {pathName === "/wnwn/admin/orders" && <Orders />}
              {pathName === "/wnwn/admin/transactions" && (
                <Transactions />
              )}
              {pathName === "/wnwn/admin/invoices" && (
                <Invoices history={history} />
              )}
              {pathName === "/wnwn/admin/add-bag" && (
                <AddNewBag history={history} />
              )}
              {pathName === "/wnwn/admin/add-invoice" && (
                <AddNewInvoice history={history} />
              )}
              {pathName === "/wnwn/admin/invoice" && (
                <InvoiceDetails history={history} />
              )}
              {pathName === "/wnwn/admin/profile" && (
                <Profile history={history} />
              )}
            </div>
          </div>
        </div>
        {showSidebar && (
          <div
            className="layout-overlay-sidebar"
            onClick={() => setShowSidebar(false)}></div>
        )}
      </div>
    </PrivateLayout>
  );
};

export default Dashboard;
