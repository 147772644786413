import React, { useEffect, useState } from "react";
import { getLocalData } from "../../utils/functions";
import DeleteUserModal from "../../modals/delete-user";
import { useDispatch } from "react-redux";
import { getCurrentUser } from "../../store/actions/dashboardAction";
import logo from "../../assets/home-img/logo.png";

const Sidebar = ({ pathName, history, showSidebar, setShowSidebar }) => {
  const [showLogout, setShowLogout] = useState(false);

  const dispatch = useDispatch();
  const userType = getLocalData("userType");

  useEffect(() => {
    dispatch(getCurrentUser());
  }, []);

  const logoutHandler = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("userName");
    localStorage.removeItem("userType");
    history.push("/wnwn/admin/login");
  };

  return (
    <aside
      id="layout-menu"
      className={`layout-menu menu-vertical menu bg-menu-theme ${
        showSidebar ? "show-sidebar" : ""
      } `}>
      <div className="sidebar-header"></div>
      <div
        className="py-lg-3 mb-3 px-2"
        style={{
          display: "flex",
          alignContent: "center",
          justifyContent: "center",
          marginTop: -80,
        }}>
        <img
          src={logo}
          width="80"
        />
        {/* <h6 style={{ color: "#333" }}>Waste Not  Want Not</h6> */}
      </div>

      <div id="menu-scroll">
        <div className="menu-block">
          <ul className="menu-inner">
            {userType == 3 && (
              <>
                <li
                  className={`menu-item ${
                    pathName === "/wnwn/admin/stores" ||
                    pathName === "/wnwn/admin"
                      ? "active"
                      : ""
                  }`}>
                  <a
                    role="button"
                    onClick={() => {
                      history.push("/wnwn/admin/stores");
                      setShowSidebar(false);
                    }}
                    className="menu-link">
                    <i className="menu-icon tf-icons fas fa-utensils"></i>
                    <div>Stores</div>
                  </a>
                </li>
                <li
                  className={`menu-item ${
                    pathName === "/wnwn/admin/bags" ? "active" : ""
                  }`}>
                  <a
                    role="button"
                    onClick={() => {
                      history.push("/wnwn/admin/bags");
                      setShowSidebar(false);
                    }}
                    className="menu-link">
                    <i className="menu-icon tf-icons fas fa-pizza"></i>
                    <div>Meals</div>
                  </a>
                </li>
                <li
                  className={`menu-item ${
                    pathName === "/wnwn/admin/orders" ? "active" : ""
                  }`}>
                  <a
                    role="button"
                    onClick={() => {
                      history.push("/wnwn/admin/orders");
                      setShowSidebar(false);
                    }}
                    className="menu-link">
                    <i className="menu-icon tf-icons far fa-bullhorn"></i>
                    <div>Orders</div>
                  </a>
                </li>
                <li
                  className={`menu-item ${
                    pathName === "/wnwn/admin/transactions"
                      ? "active"
                      : ""
                  }`}>
                  <a
                    role="button"
                    onClick={() => {
                      history.push("/wnwn/admin/transactions");
                      setShowSidebar(false);
                    }}
                    className="menu-link">
                    <i className="menu-icon tf-icons fas fa-money-bill"></i>
                    <div>Transactions</div>
                  </a>
                </li>
                <li
                  className={`menu-item ${
                    pathName === "/wnwn/admin/invoices"
                      ? "active"
                      : ""
                  }`}>
                  <a
                    role="button"
                    onClick={() => {
                      history.push("/wnwn/admin/invoices");
                      setShowSidebar(false);
                    }}
                    className="menu-link">
                    <i className="menu-icon tf-icons fas fa-receipt"></i>
                    <div>Invoices</div>
                  </a>
                </li>
              </>
            )}
            {userType == 2 && (
              <>
                <li
                  className={`menu-item ${
                    pathName === "/wnwn/admin/bags" ? "active" : ""
                  }`}>
                  <a
                    role="button"
                    onClick={() => {
                      history.push("/wnwn/admin/bags");
                      setShowSidebar(false);
                    }}
                    className="menu-link">
                    <i className="menu-icon tf-icons fas fa-pizza"></i>
                    <div>Bags</div>
                  </a>
                </li>
                <li
                  className={`menu-item ${
                    pathName === "/wnwn/admin/orders" ? "active" : ""
                  }`}>
                  <a
                    role="button"
                    onClick={() => {
                      history.push("/wnwn/admin/orders");
                      setShowSidebar(false);
                    }}
                    className="menu-link">
                    <i className="menu-icon tf-icons far fa-bullhorn"></i>
                    <div>Orders</div>
                  </a>
                </li>
                <li
                  className={`menu-item ${
                    pathName === "/wnwn/admin/invoices"
                      ? "active"
                      : ""
                  }`}>
                  <a
                    role="button"
                    onClick={() => {
                      history.push("/wnwn/admin/invoices");
                      setShowSidebar(false);
                    }}
                    className="menu-link">
                    <i className="menu-icon tf-icons fas fa-receipt"></i>
                    <div>Invoices</div>
                  </a>
                </li>
                <li
                  className={`menu-item ${
                    pathName === "/wnwn/admin/profile"
                      ? "active"
                      : ""
                  }`}>
                  <a
                    role="button"
                    onClick={() => {
                      history.push("/wnwn/admin/profile");
                      setShowSidebar(false);
                    }}
                    className="menu-link">
                    <i className="menu-icon tf-icons fas fa-user"></i>
                    <div>Profile</div>
                  </a>
                </li>
              </>
            )}
            {/* <li
              className={`menu-item ${pathName === "/wnwn/admin/quality-assurance" ? "active" : ""
                }`}>
              <a
                role="button"
                onClick={() => {
                  history.push("/wnwn/admin/quality-assurance");
                  setShowSidebar(false);
                }}
                className="menu-link">
                <i className="menu-icon tf-icons far fa-users"></i>
                <div>Quality Assurance</div>
              </a>
            </li> */}
            {/* 
            <li
              className={`menu-item ${pathName === "/wnwn/admin/statistics" ? "active" : ""
                }`}>
              <a
                role="button"
                onClick={() => {
                  history.push("/wnwn/admin/statistics");
                  setShowSidebar(false);
                }}
                className="menu-link">
                <i className="menu-icon tf-icons far fa-user-alt"></i>
                <div>Statistics</div>
              </a>
            </li> */}
            <li className={`menu-item `}>
              <a
                className="menu-link"
                role="button"
                onClick={() => setShowLogout(true)}>
                <i className="far fa-power-off me-2"></i>
                <div>Log Out</div>
              </a>
            </li>
          </ul>
        </div>
      </div>
      <DeleteUserModal
        modalIsOpen={showLogout}
        setIsOpen={setShowLogout}
        closeModal={() => setShowLogout(false)}
        deleteHandler={logoutHandler}
        logout={true}
      />
    </aside>
  );
};

export default Sidebar;
